import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const notification = ({ title = "Bank not available", message = "", type = "error" }) => {
  return MySwal.fire({
    title,
    text: message || "",
    icon: type,
    showConfirmButton: false,
    backdrop: false,
    shadow: false,
  });
};

export default notification;
