import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Collapse,
} from "reactstrap";
import { Lock, ChevronDown } from "react-feather";
import safeConnectLogo from "@src/assets/images/logo/safeConnect.png";
import logo from "@src/assets/images/logo/logo.png";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { standardCase } from "../utils";
import { Store } from "../Home";
import classnames from "classnames";

const Consent = () => {
  const {
    selectedCountry,
    globals,
    selectedBank,
    psuId,
    setPsuId,
    handleConsentConfirm,
    consentModal,
    setConsentModal,
  } = useContext(Store);

  const [showPSU, setShowPSU] = useState(false);
  const toggleConsentModal = () => setConsentModal(false);

  useEffect(() => {
    let institution_id = selectedBank?.id;
    if (
      institution_id == "deutschebank-pfb_de" ||
      institution_id == "deutschebank-noris_de" ||
      institution_id == "deutschebank-sandbox"
    )
      setShowPSU(true);
    else setShowPSU(false);
  }, [selectedBank]);

  // Modal specific state
  const [collapseID, setCollapseID] = useState("");
  const [status, setStatus] = useState("closed");
  const toggleCollapse = (newCollapseId) =>
    setCollapseID(collapseID !== newCollapseId ? newCollapseId : "");
  const onStatusChange = (id, newStatus) =>
    id === collapseID ? setStatus(newStatus) : false;

  const isUK = selectedCountry?.country == "GB";
  const payeeAccount = globals?.accounts?.find((el) =>
    isUK ? el.currency == "GBP" : el.currency == "EUR"
  );

  const infoToShow = [
    // {
    //   id: 1,
    //   title: "Data Sharing",
    //   content: () => (
    //     <div>
    //       SafeConnect will retrieve bank data needed to facilitate this payment based on your request and provide this
    //       information to Zotto Limited.
    //     </div>
    //   ),
    // },
    // {
    //   id: 2,
    //   title: "Secure connection",
    //   content: () => (
    //     <div>
    //       Data is securely accessed in read-only format and only for the purposes of this payment request. This request
    //       is a one off, you will not receive any other requests from SafeConnect for this payment.
    //     </div>
    //   ),
    // },
    // {
    //   id: 3,
    //   title: !isUK ? "Bank of Lithuania Authorisation" : "FCA Authorisation",
    //   content: () => {
    //     return !isUK ? (
    //       <div>
    //         SafeConnect UAB is authorised and regulated by the Bank of Lithuania under Payments Law (LB002045) for the
    //         provision of Account Information and Payment Initiation services
    //       </div>
    //     ) : (
    //       <div>
    //         SafeConnect Ltd is authorised and regulated by the Financial Conduct Authority under the Payment Service
    //         Regulations 2017 [827001] for the provision of Account Information and Payment Initiation services.
    //       </div>
    //     );
    //   },
    // },
    {
      id: 4,
      title: "About the Access",
      content: () => (
        <Fragment>
          <div>
            To easily set up payments from your bank to Zotto Limited , we are
            about to securely re-direct you to your bank where you will be asked
            to confirm the payment via Nuapay, an{" "}
            {isUK ? "FCA" : "Bank of Lithuania"} regulated payment initiation
            provider for Zotto Limited
          </div>
          <br />
          <div>
            Nuapay will share these details with your bank, where you will
            then be asked to confirm the following payment setup.
          </div>
          <div className="mt-1">
            <div className="font-small-3 font-weight-bold">
              Payee Information
            </div>
            <div style={{ marginTop: "0.2rem" }} className="font-small-3">
              <div>Payee Name: {payeeAccount.name}</div>
              {isUK ? (
                <Fragment>
                  <div>Sort Code: {payeeAccount.sort_code}</div>
                  <div>Account Number: {payeeAccount.account_number}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <div>Bic/Swift Code: {payeeAccount.bic}</div>
                  <div>IBAN: {payeeAccount.iban}</div>
                </Fragment>
              )}
              <div>Payment Ref: {globals?.transaction?.order_id}</div>
            </div>
          </div>
          <div className="mt-1">
            <h6 className="font-small-3 font-weight-bold">
              Payment Information
            </h6>
            <div style={{ marginTop: "0.2rem" }} className="font-small-3">
              <div>Bank Name: {selectedBank.name}</div>
              <div>Bank Country: {standardCase(selectedCountry.name)}</div>
            </div>
          </div>
          <h6 className="mt-1">
            Total Amount:
            <span style={{ marginLeft: "0.5rem" }}>
              {new Intl.NumberFormat("en-UK", {
                style: "currency",
                currency: "GBP",
              }).format(globals?.transaction?.amount)}
            </span>
            {/* {conversionDetails.amount ? (
              <span style={{ marginLeft: "0.5rem" }} className="primary">
                (
                {new Intl.NumberFormat("en-UK", {
                  style: "currency",
                  currency: conversionDetails.currency,
                })
                  .format(conversionDetails.amount)
                  .replace(/^(\D+)/, "$1 ")}
                )
              </span>
            ) : convertingMoney ? (
              <Spinner size="sm ml-1" color="primary" />
            ) : null} */}
          </h6>
          <div>
            Terms of Service:{" "}
            <a href="https://www.nuapay.com/en/pisp-psu-terms-of-service">
              https://www.nuapay.com/en/pisp-psu-terms-of-service
            </a>
          </div>
          <div>
            About:{" "}
            <a href="https://www.nuapay.com/en/pisp-information">
              https://www.nuapay.com/en/pisp-information
            </a>
          </div>
        </Fragment>
      ),
    },
  ];

  return (
    <Modal
      isOpen={consentModal}
      toggle={toggleConsentModal}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={toggleConsentModal}>
        {/* <img src={safeConnectLogo} width="150" /> */}
        <img src={logo} id="small-screen-logo" />
      </ModalHeader>
      <ModalBody className="modal-dialog-centered">
        <div>
          <div>
            <div className="vx-collapse collapse-bordered">
              {infoToShow.map((collapseItem) => (
                <div
                  key={collapseItem.id}
                  className={classnames({
                    "collapse-collapsed":
                      status === "closed" && collapseID === collapseItem.id,
                    "collapse-shown":
                      status === "opened" && collapseID === collapseItem.id,
                    closing:
                      status === "closing" && collapseID === collapseItem.id,
                    opening:
                      status === "opening" && collapseID === collapseItem.id,
                  })}
                >
                  <div
                    className="d-flex justify-content-between"
                    onClick={() => toggleCollapse(collapseItem.id)}
                  >
                    <div className="lead collapse-title collapsed">
                      {collapseItem.title}
                    </div>
                    <ChevronDown
                      size={15}
                      className="collapse-icon cursor-pointer"
                    />
                  </div>
                  <Collapse
                    isOpen={
                      collapseItem.id === collapseID || collapseItem.id === 4
                    }
                    onEntering={() =>
                      onStatusChange(collapseItem.id, "opening")
                    }
                    onEntered={() => onStatusChange(collapseItem.id, "opened")}
                    onExiting={() => onStatusChange(collapseItem.id, "closing")}
                    onExited={() => onStatusChange(collapseItem.id, "closed")}
                  >
                    <div
                      className="font-small-2"
                      style={{ marginTop: "0.5rem" }}
                    >
                      {collapseItem.content()}
                    </div>
                  </Collapse>
                  <hr />
                </div>
              ))}
            </div>
          </div>
          {showPSU ? (
            <div>
              <Input
                value={psuId}
                style={{ fontSize: "13px" }}
                onChange={(e) => setPsuId(e.target.value)}
                placeholder={`Enter login ID for your bank`}
              />
              <hr />
            </div>
          ) : null}
          <div className="font-small-1 d-flex align-items-start">
            <Lock size={12} style={{ marginRight: "0.2rem" }} />
            <div>
              This consent request is a one-off, you will not receive additional
              requests once completed.
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={showPSU && psuId.trim().length == 0}
          onClick={handleConsentConfirm}
        >
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Consent;
