import { formatMoney } from "../utils";
import { Row, Col } from "reactstrap";
import { Lock, ArrowLeft } from "react-feather";
import { useEffect } from "react";
import logo from "../../assets/images/logo/zotto_logo_1_2x.png";
import "./PayntStyle.css";


const Paynt = ({ data }) => {
  const goBack = () => {
    if (data.back_url) window.location.href = data.back_url;
  };

  useEffect(() => {
    window.merchantId = data.merchantId
    window.gatewayMerchantId = data.entityId

    const script = document.createElement("script");
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = "https://code.jquery.com/jquery-3.6.3.js";
    script.src =
      "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=" +
      data.checkoutId;
    script2.src = require("./payntscript");

    script.async = true;
    script1.async = true;
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    script2.onload = (ev) => {
      console.log(ev);
      document.body.append(script);
    };

    script.onerror = (e) => {
      console.log(e);
    };
  }, [data]);

  return (
    <div className="h-100">
      <div
        className="cursor-pointer text-align-left d-flex w-100 d-lg-none"
        style={{
          position: "relative",
          padding: "20px",
          position: "fixed",
          zIndex: 2,
          borderBottom: "1px solid #d8d6de",
          background: "#fff",
        }}
        onClick={goBack}
      >
        <ArrowLeft className="margin-auto" style={{ marginRight: "20px" }} />
        {data.logo ? (
          <img
            className="img-fluid responsive-brand-logo"
            style={{ width: "30%" }}
            src={logo}
          />
        ) : (
          <h4 style={{ margin: "auto 0" }}>Back</h4>
        )}
      </div>
      <Row className="h-100 m-0">
        <Col lg="6" className="d-none d-lg-block p-0">
          <div className="d-flex align-items-center h-100 justify-content-end">
            <div
              style={{
                width: "540px",
                height: "413px",
                paddingLeft: "6em",
                paddingRight: "6em",
              }}
              className="d-flex flex-column justify-content-between responsive-padding"
            >
              <div>
                <div
                  className="cursor-pointer text-align-left"
                  style={{ width: "fit-content", position: "relative" }}
                  onClick={goBack}
                >
                  <ArrowLeft
                    className="margin-auto"
                    style={{
                      position: "absolute",
                      left: "-50px",
                      top: "50%",
                      transform: "translate(0, -50%)",
                    }}
                  />
                  {data.logo ? (
                    <img
                      className="img-fluid responsive-brand-logo"
                      style={{ width: "70%" }}
                      src={logo}
                    />
                  ) : (
                    <h4 style={{ margin: "auto 0" }}>Back</h4>
                  )}
                </div>
                <div className="my-4">
                  <div className="font-weight-bolder">Pay</div>
                  <div className="font-large-1 font-weight-bolder">
                    {formatMoney(data.amount, data.currency)}
                  </div>
                </div>
              </div>

              <div className="font-small-2" style={{ textAlign: "justify" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <hr style={{ width: "40%", marginLeft: 0 }} />
                  <Lock size={14} />
                  <hr style={{ width: "40%", marginRight: 0 }} />
                </div>
                <p className="mb-1">
                  All transaction information passed to, and used on, this
                  payment form is encrypted during sending using the latest
                  encryption and hashing techniques available. No personal
                  information (including your card details) is ever passed over
                  the internet or stored on our systems unencrypted.
                </p>
                {data.email && (
                  <i> For any queries please contact to {data.email} </i>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col lg="6" className="p-0" style={{ backgroundColor: "white" }}>
          <div className="d-flex align-items-center">
            <div
              className="main-content mx-auto mx-lg-0 responsive-padding"
              style={{
                width: "540px",
                height: "413px",
                paddingLeft: "6em",
                paddingRight: "6em",
              }}
            >
              <form
                data-brands="GOOGLEPAY VISA MASTER RUPAY APPLEPAY"
                action={`https://paymentz.z-pay.co.uk/api/payntShopperResultUrl/`}
                className="paymentWidgets"
              />
            </div>
          </div>

        </Col>
      </Row>
    </div>
  );
};

export default Paynt;
