import React from "react";
import { useLocation } from "react-router-dom";

const PayntShopperResultUrl = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const resourcePath = queryParameters.get("resourcePath");

//   const {
//     data: { data },
//   } = await api.get(`/api/payment-details/${uniqueKey}`);


  return (
    <div>
        <h1>id: {id}</h1>
        <h1>resourcepath:{resourcePath}</h1>
    </div>
  )
};

export default PayntShopperResultUrl;
