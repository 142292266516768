import { createContext, Fragment, useEffect, useMemo, useState } from "react";
import BanksList from "./components/BanksList";
import Sidebar from "./components/Sidebar";
import Loader from "./components/Loader";
import api from "./api";
import { useParams } from "react-router-dom";
import CountrySelect from "./components/CountrySelect";
import Consent from "./components/Consent";
import notification from "./components/notification";
import PaymentMethod from "./components/PaymentMethod";
import Trustpay from "./components/trustpay";
import DebitorInformation from "./components/DebitorInformation";
import * as qs from "qs";
import axios from "axios";
import Paynt from "./components/Paynt";

export const Store = createContext(null);

const Home = () => {
  const { uniqueKey } = useParams();
  const [globals, setGlobals] = useState({});
  const [loading, setLoading] = useState(true);
  const [consentModal, setConsentModal] = useState(false);
  const [countrySelectModal, setCountrySelectModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const [ibanNumber, setIbanNumber] = useState("");
  const [ibanSelectModal, setIbanSelectModal] = useState(false);
  const [psuId, setPsuId] = useState("");
  const [trustPayData, setTrustPayData] = useState({});
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(false);
  let [process, setProcessCheckout] = useState(false);
  const [payntData, setPayntData] = useState({});
  let [payntCheckout, setPayntCheckout] = useState(false);
  const [notify, setNotify] = useState(false);
  const [aspRoute, setAspRoute] = useState("");

  const showNotification = (data) => {
    document.querySelector("body").style.background = "#fff";
    setNotify(true);
    notification(data);
  };

  const getPaymentDetails = async () => {
    try {
      const {
        data: { data },
      } = await api.get(`/api/payment-details/${uniqueKey}`);

      // Redirect if card payment
      if (data.transaction.redirect_type == 3) setSelectPaymentMethod(true);
      else if (
        data.transaction.redirect_type == 1 &&
        data.transaction.checkoutId == null &&
        data.transaction.payment_status == "NONE" &&
        data?.transaction?.card_gateway != "TRUSTPAY"
      ) {
        window.location.href = data.transaction.pay_link;
      } else if (
        data.transaction.redirect_type == 1 &&
        data.transaction.checkoutId == null &&
        data.transaction.payment_status == "NONE" &&
        data?.transaction?.card_gateway == "TRUSTPAY"
      ) {
        setTrustPayData(data.transaction);
        setProcessCheckout(true);
      } else if (
        data.transaction.redirect_type == 1 &&
        data.transaction.checkoutId != null &&
        data.transaction.payment_status == "NONE" &&
        data?.transaction?.card_gateway == "PAYNT"
      ) {
        setPayntData(data.transaction);
        setPayntCheckout(true);
      } else if (
        data.transaction.redirect_type == 1 &&
        data.transaction.payment_status != "NONE"
      ) {
        switch (data.transaction.payment_status) {
          case "COMPLETED":
            showNotification({ type: "success", title: "Payment Completed!" });
            return;
          case "PENDING":
            showNotification({ type: "success", title: "Payment Completed!" });
            return;
          case "FAILED":
            showNotification({ type: "error", title: "Payment Failed!" });
            return;
        }
      } else if (data.transaction.redirect_type == 2) {
        const { transaction, banks, countries } = data;
        switch (transaction.payment_status) {
          case "COMPLETED":
            showNotification({
              type: "success",
              title: "Payment Completed!",
              message: "Close this window",
            });
            return;
          case "PENDING":
            showNotification({
              type: "warning",
              title: "Payment Pending",
              message: "Please wait for some time",
            });
            return;
          case "FAILED":
            showNotification({ type: "error", title: "Payment Failed!" });
            return;
        }

        data?.banks.sort((a, b) => (a.name > b.name ? 1 : -1));

        // if account conset given, then directly show payment consent modal
        if (transaction.bank_id) {
          const bank = banks.find((el) => el.id == transaction.bank_id);
          var country = countries.find(
            (el) => el.country == transaction.bank_country
          );
          setSelectedBank({
            id: bank.id,
            name: bank.name,
            country: transaction.bank_country,
            countryCode: bank.supportedCurrencies[0],
          });
          setSelectedCountry({ country: "india" });
          setConsentModal(true);
        }

        setSelectedCountry({
          name: data.country.name,
          country: data.country.country,
        });

        // const country = data.country;
        // delete data.country;
        if (country) data.selectedCountry = setSelectedCountry(country);
        else
          setSelectedCountry({
            name: data.country.name,
            country: data.country.country,
          });
        setGlobals(data);
        setLoading(false);
      } else {
        setGlobals(data);
        setLoading(false);
        // throw "Invalid Payment Link!";
      }
    } catch (error) {
      console.log(error);
      showNotification({ type: "error", title: "Invalid Payment Link!" });
    }
  };

  const handleConsentConfirm = async () => {
    setConsentModal(false);

    if (selectedBank.debtorAccountRequired) {
      setIbanSelectModal(true);
    } else {
      setLoading("processing");
      const isUK = selectedCountry.country == "GB";
      // const dataToSend = {
      //   uniqueKey,
      //   selectedBank,
      //   psuHeaders: { "psu-id": psuId },
      // };

      const dataTosend = {
        uniqueKey: uniqueKey,
        bankId: selectedBank.id,
        bankName: selectedBank.name,
        countryCode: selectedBank.country,
        currencyCode: selectedBank.currencyCode,
      };

      let response;
      try {
        // yapily code
        // if (globals.transaction.bank_id == selectedBank.id || isUK)
        //   response = await api.post(
        //     "/open-banking/payment-consent-url",
        //     dataToSend
        //   );
        // else
        //   response = await api.post(
        //     "/open-banking/account-consent-url",
        //     dataToSend
        //   );

        const response = await api.post(
          "/api/create-nuapay-payment",
          dataTosend
        );

        if (response) {
          setAspRoute(response.data.data.data.data.aspspAuthUrl);
        }
      } catch (err) {
        notification({ message: "Please try again later!" });
      }
    }
  };
  const handleIbanConsentConfirm = async () => {
    setConsentModal(false);

    setLoading("processing");
    const isUK = selectedCountry.country == "GB";
    // const dataToSend = {
    //   uniqueKey,
    //   selectedBank,
    //   psuHeaders: { "psu-id": psuId },
    // };

    const dataTosend = {
      uniqueKey: uniqueKey,
      bankId: selectedBank.id,
      bankName: selectedBank.name,
      countryCode: selectedBank.country,
      currencyCode: selectedBank.currencyCode,
      iban: ibanNumber,
    };

    let response;
    try {
      // yapily code
      // if (globals.transaction.bank_id == selectedBank.id || isUK)
      //   response = await api.post(
      //     "/open-banking/payment-consent-url",
      //     dataToSend
      //   );
      // else
      //   response = await api.post(
      //     "/open-banking/account-consent-url",
      //     dataToSend
      //   );

      const response = await api.post("/api/create-nuapay-payment", dataTosend);

      if (response) {
        setAspRoute(response.data.data.data.data.aspspAuthUrl);
      }
    } catch (err) {
      notification({ message: "Please try again later!" });
    }
  };

  if (aspRoute && aspRoute != "") {
    window.location.href = aspRoute;
  }

  const handlePaymentMethodSelect = async (selectedType = 1) => {
    try {
      await api.post(
        `/update-payment-method/${uniqueKey}?payment_method=${selectedType}`
      );
      window.location.reload();
    } catch (err) {
      notification({ message: "Please try again later!" });
    }
  };

  const providerValue = useMemo(
    () => ({
      globals,
      consentModal,
      setConsentModal,
      countrySelectModal,
      setCountrySelectModal,
      selectedCountry,
      setSelectedCountry,
      selectedBank,
      setSelectedBank,
      ibanNumber,
      setIbanNumber,
      ibanSelectModal,
      setIbanSelectModal,
      psuId,
      setPsuId,
      handleConsentConfirm,
      handleIbanConsentConfirm,
      loading,
    }),
    [
      selectedBank,
      selectedCountry,
      globals,
      countrySelectModal,
      consentModal,
      ibanSelectModal,
      ibanNumber,
      psuId,
      loading,
    ]
  );

  useEffect(getPaymentDetails, []);

  if (Object.keys(trustPayData).length > 0 && process) {
    return <Trustpay data={trustPayData} />;
  }

  if (Object.keys(payntData).length > 0 && payntCheckout) {
    return <Paynt data= {payntData} />
  }

  if (notify) return null;
  else if (selectPaymentMethod)
    return (
      <PaymentMethod handlePaymentMethodSelect={handlePaymentMethodSelect} />
    );

  return (
    <Store.Provider value={providerValue}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Sidebar />
          {ibanSelectModal ? <DebitorInformation /> : <BanksList />}
          <CountrySelect />
          <Consent />
          {/* { ibanSelectModal && <DebitorAccountDetails />} */}
        </Fragment>
      )}
    </Store.Provider>
  );
};

export default Home;
