import { BrowserRouter as AppRouter, Route, Switch } from "react-router-dom";
import Home from "./views/Home";
import Error from "./views/Error";
import PayntShopperResultUrl from "./views/components/PayntShopperResultUrl";

const Router = () => {
  return (
    <AppRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route exact path="/payment-checkout/:uniqueKey" component={Home} />
        <Route path="/payntShopperResultUrl/" component={PayntShopperResultUrl} />
        <Route path="*" component={Error} />/
      </Switch>
    </AppRouter>
  );
};

export default Router;
