import React from "react";
import { Card, CardBody } from "reactstrap";
import { Briefcase, CreditCard } from "react-feather";

export default function PaymentMethod({ handlePaymentMethodSelect }) {
  return (
    <div className="m-2">
      <div className="font-large-1 text-center mb-2 mt-3">Choose your payment method</div>
      <div className="d-flex justify-content-center flex-wrap">
        <Card
          style={{ width: "350px" }}
          className="m-md-2 cursor-pointer text-center"
          onClick={() => handlePaymentMethodSelect("2")}
        >
          <CardBody>
            <Briefcase size={100} />
            <hr />
            <div className="mt-1 font-large-1">Open Banking</div>
          </CardBody>
        </Card>
        <Card
          style={{ width: "350px" }}
          className="m-md-2 cursor-pointer text-center"
          onClick={() => handlePaymentMethodSelect("1")}
        >
          <CardBody>
            <CreditCard size={100} />
            <hr />
            <div className="mt-1 font-large-1">Card Payment</div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
