const standardCase = (text = "") => {
  const words = text.trim().split(" ");
  words.forEach((el, i) => {
    words[i] = el.slice(0, 1).toUpperCase() + el.slice(1).toLowerCase();
  });
  return words.join(" ");
};

const formatMoney = (amount, currency) => {
  return new Intl.NumberFormat(
    "en-UK",
    currency
      ? {
          style: "currency",
          currency,
        }
      : {}
  )
    .format(Math.round((amount || 0) * 100) / 100)
    .replace(/^(\D+)/, "$1 ");
};


export { standardCase, formatMoney };
