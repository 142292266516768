import { Input, Button } from "reactstrap";
import React, { Fragment, useContext } from "react";

import logo from "@src/assets/images/logo/logo.png";

import "@styles/base/pages/app-ecommerce.scss";
import { Store } from "../Home";
import { standardCase } from "../utils";

const DebitorInformation = () => {
  const {
    globals,
    selectedBank,
    ibanNumber,
    setIbanNumber,
    selectedCountry,
    setCountrySelectModal,
    handleIbanConsentConfirm,
    setIbanSelectModal
  } = useContext(Store);

  return (
    <Fragment>
      <div className="debitorInformation-select-container">
        <div className="debitorInformation-select-section">
          <div
            id="small-screen-info"
            className="mb-3 d-flex justify-content-between search-product align-items-center"
          >
            <div>
              <img src={logo} id="small-screen-logo" />
            </div>
            <div>
              <div className="text-right">
                <div className="font-small-1">Amount</div>
                <div className="text-bold-500">
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: globals?.transaction?.currency,
                  })
                    .format(globals?.transaction?.amount)
                    .replace(/^(\D+)/, "$1 ")}
                  {/* {new Intl.NumberFormat("en-UK", {
                    currency: globals?.transaction?.currency,
                  })
                    .format(globals?.transaction?.amount)
                    .replace(/^(\D+)/, "$1 ")} */}
                </div>
              </div>

              <div
                className="text-right"
                onClick={() => setCountrySelectModal(true)}
                style={{ marginTop: "0.7rem", maxWidth: "180px" }}
              >
                <div className="font-small-1">country</div>
                <div className="text-bold-500 cursor-pointer">
                  {standardCase(selectedCountry?.name)}
                </div>
                <div className="font-small-1 cursor-pointer">
                  <u>
                    <i>change country</i>
                  </u>
                </div>
              </div>
            </div>
          </div>

          <div className="debitorInformation-sec">
            <div className="debitorInformation-bankDetails mt-5 mb-3">
              <img
                src={selectedBank.logo}
                style={{ height: "100px" }}
                className="bank-logo"
              />
              <p>{selectedBank.name}</p>
            </div>
            <div className="font-small-3 d-flex align-items-start">
              <div>
                You must log on to bank to authorize the payment. <br /> Your
                bank requires that you provide your IBAN.
              </div>
            </div>

            <div className=" debitorInformation-input-sec">
              <p>IBAN</p>
              <Input
                placeholder="IBAN"
                value={ibanNumber}
                onChange={(e) => setIbanNumber(e.target.value)}
              />
            </div>

            <div className="debitorInformation-action-sec mt-3">
              <Button
                color="primary"
                disabled={ibanNumber.length === 0}
                onClick={handleIbanConsentConfirm}
              >
                Accept
              </Button>
              <div
                className=" text-center font-small-2 cursor-pointer mt-2"
                onClick={() => {
                 setIbanSelectModal(false)
                }}
              >
                <i>
                  <u>Go Back</u>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DebitorInformation;
