import React, { useEffect } from "react";


function trustpay({ data }) {
  console.log(data)
  let checkout = () => {
    document.getElementById("submit").style.display = "none";
    document.getElementById("submit").click();
  };

  useEffect(() => {
      checkout();
  
  });
  

  return (
    <>
      <form
      method="POST"
      action="https://payments.securetrading.net/process/payments/choice"
    >
      <input type="hidden" name="sitereference" value={data.sitereference} />
      <input type="hidden" name="stprofile" value="zotto" />
      <input type="hidden" name="currencyiso3a" value={data.currency} />
      <input type="hidden" name="stextraurlnotifyfields" value="customeremail" />
      <input type="hidden" name="stextraurlnotifyfields" value="customerfirstname" />
      <input type="hidden" name="strequiredfields" value="customeremail" />
      <input type="hidden" name="strequiredfields" value="customerfirstname" />
      <input type="hidden" name="strequiredfields" value="customerlastname" />
      <input type="hidden" name="stextraurlnotifyfields" value="customerlastname" />
      <input type="hidden" name="mainamount" value={data.amount} />
      <input type="hidden" name="orderreference" value={data.unique_link_key}/>
      <input type="hidden" name="declinedurlredirect" value={data.failed_url ? data.failed_url : `${data.redirect_url}${data.unique_link_key}` }/>
      <input type="hidden" name="successfulurlredirect" value={data.accept_url ? data.accept_url :`${data.redirect_url}${data.unique_link_key}`}/>
      <input type="hidden" name="version" value="2" />
      <input type="hidden" name="sitesecurity" value={data.hash}/>
      <input type="hidden" name="sitesecuritytimestamp" value={data.timeStamp} />
      <input type="hidden" name="allurlnotification" value={data.webhook_url}/>
      <button type="submit" id="submit" value="Pay"></button>
    </form>
    
    </>
  
  );
}

export default trustpay;
