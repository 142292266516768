import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import React, { useContext, useState } from "react";
import LazyLoad from "react-lazyload";
import { Store } from "../Home";
import { standardCase } from "../utils";

export default function CountrySelect() {
  const { globals, selectedCountry, setSelectedCountry, setCountrySelectModal, countrySelectModal } = useContext(Store);
  const [filter, setFilter] = useState("");

  const toggleModal = () => {
    setCountrySelectModal(false);
    setFilter("");
  };

  const handleCountrySelect = (newCountry) => {
    setSelectedCountry(newCountry);
    toggleModal();
  };

  const filteredCountries = () =>
    globals?.countries?.filter((el) => el.name.toLowerCase().includes(filter.trim().toLowerCase())) || [];

  return (
    <Modal isOpen={countrySelectModal} toggle={toggleModal} className="modal-dialog-centered modal-sm">
      <ModalHeader toggle={toggleModal}>Change Your country</ModalHeader>
      <ModalBody className="modal-dialog-centered d-flex flex-column">
        <div className="full-width">
          <div className="full-width">
            <Input placeholder="Search your country" value={filter} onChange={(e) => setFilter(e.target.value)} />
          </div>
          <br />
          {/* <PerfectScrollbar style={{ width: "100%" }} options={{ minScrollbarLength: 30 }}> */}
          <div style={{ height: "380px" }} className="country-list">
            {filteredCountries().map((country, i) => {
              return (
                <LazyLoad key={i}>
                  <div
                    onClick={() => handleCountrySelect(country)}
                    className="d-flex flex-row align-items-center mb-2 cursor-pointer"
                    style={{
                      border: "1px solid",
                      borderColor: country.country == selectedCountry?.country ? "#7367f0" : "#d9d9d9",
                      padding: "1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={country.country}
                      svg
                      className="mr-1"
                      style={{
                        width: "3em",
                        height: "2em",
                      }}
                    />
                    <div className="font-medium-1">{standardCase(country.name)}</div>
                  </div>
                </LazyLoad>
              );
            })}
          </div>
        </div>
        {/* </PerfectScrollbar> */}
      </ModalBody>
    </Modal>
  );
}
