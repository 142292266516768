import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import { Lock } from "react-feather";
import logo from "@src/assets/images/logo/logo.png";
import { Store } from "../Home";
import { standardCase } from "../utils";


export default function Sidebar() {
  const { setCountrySelectModal, selectedCountry, globals, ibanSelectModal } =
    useContext(Store);

  return (
    <Card
      className="sidebar-info"
      style={{
        height: "100vh",
        width: "360px",
        position: "fixed",
      }}
    >
      <CardBody className="text-center pr-5 pl-5">
        <div className="mt-3">
          <img src={logo} />
        </div>
        <div className="mt-4">
          <p>Payment Ref</p>
          <h4 style={{ marginTop: "-0.7rem" }}>
            {globals?.transaction?.order_id}
          </h4>
        </div>
        <div className="mt-2">
          <p>Amount</p>
          <h4 style={{ marginTop: "-0.7rem" }}>
            {new Intl.NumberFormat("en-UK", {
              style: "currency",
              currency: globals?.transaction?.currency,
            })
              .format(globals?.transaction?.amount)
              .replace(/^(\D+)/, "$1 ")}
          </h4>
        </div>
        {!ibanSelectModal ? (
          <div className="mt-2 mb-2">
            <p>Country</p>
            <h4 style={{ marginTop: "-0.7rem" }}>
              {standardCase(selectedCountry?.name)}
            </h4>
            <p
              className="font-small-1 cursor-pointer"
              style={{ marginTop: "-0.5rem" }}
              onClick={() => setCountrySelectModal(true)}
            >
              <i>
                <u>change country</u>
              </i>
            </p>
          </div>
        ) : null}
        <hr className="mt-4 mb-2" />
        <div>
          <Lock size={30} />
          <p className="font-small-3 mt-2">
            Pay the above amount using Open Banking, Safe and Secure. You will
            be navigated to your Selected Banking app to securely authenticate
            and make the payment.
          </p>
        </div>
        {globals?.transaction?.back_url ? (
          <div
            className=" text-center font-small-2 cursor-pointer mt-2"
            onClick={() => {
              window.location.href = globals.transaction.back_url;
            }}
          >
            <i>
              <u>Back to merchant</u>
            </i>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
}
