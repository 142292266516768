import {
  Input,
  Card,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Search } from "react-feather";
import logo from "@src/assets/images/logo/logo.png";
import React, { Fragment, useContext, useEffect, useState } from "react";

import "@styles/base/pages/app-ecommerce.scss";
import { Store } from "../Home";
import { standardCase } from "../utils";

export default function BanksList() {
  const {
    selectedCountry,
    globals,
    setSelectedBank,
    setCountrySelectModal,
    setConsentModal,
    ibanSelectModal,
  } = useContext(Store);

  const [filter, setFilter] = useState("");
  const [banks, setBanks] = useState([]);

  // set bank on bank thumnail click
  const handleBankClick = (bank) => {
    setSelectedBank({
      id: bank.id,
      name: bank.name,
      country: bank.country,
      currencyCode: bank.supportedCurrencies[0],
      debtorAccountRequired: bank.debtorAccountRequired,
      logo: bank.logo,
    });
    setConsentModal(true);
  };

  // filtered banks accoding to search
  const filteredBanks = () => {
    const searchTerm = filter.trim().toLowerCase();
    return banks?.filter(
      (el) =>
        el.name.toLowerCase().includes(searchTerm) ||
        el.fullName.toLowerCase().includes(searchTerm)
    );
  };

  // change banks list if country changes
  useEffect(() => {
    if (globals?.banks) {
      const filtered = globals.banks.filter((bank) => {
        // const n = bank.countries.length;
        // for (let i = 0; i < n; i++) {
        //   if (bank.countries[i].countryCode == selectedCountry.country) {
        //     return true;
        //   }
        // }
        if (bank.country == selectedCountry.country) {
          return true;
        }
        return false;
      });
      setBanks(filtered);
    }
  }, [selectedCountry]);

  return (
    <Fragment>
      <div className="bank-select-container">
        <div className="bank-select-section">
          <div
            id="small-screen-info"
            className="mb-3 d-flex justify-content-between search-product align-items-center"
          >
            <div>
              <img src={logo} id="small-screen-logo" />
            </div>
            <div>
              <div className="text-right">
                <div className="font-small-1">Amount</div>
                <div className="text-bold-500">
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: globals?.transaction?.currency,
                  })
                    .format(globals?.transaction?.amount)
                    .replace(/^(\D+)/, "$1 ")}
                  {/* {new Intl.NumberFormat("en-UK", {
                    currency: globals?.transaction?.currency,
                  })
                    .format(globals?.transaction?.amount)
                    .replace(/^(\D+)/, "$1 ")} */}
                </div>
              </div>
              {!ibanSelectModal ? (
                <div
                  className="text-right"
                  onClick={() => setCountrySelectModal(true)}
                  style={{ marginTop: "0.7rem", maxWidth: "180px" }}
                >
                  <div className="font-small-1">country</div>
                  <div className="text-bold-500 cursor-pointer">
                    {standardCase(selectedCountry?.name)}
                  </div>
                  <div className="font-small-1 cursor-pointer">
                    <u>
                      <i>change country</i>
                    </u>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div id="ecommerce-searchbar" className="ecommerce-searchbar">
            <InputGroup className="input-group-merge">
              <Input
                className="search-product"
                placeholder="Search your bank"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <Search className="text-muted" size={14} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
          {filteredBanks().length == 0 ? (
            <div className="text-center mt-3">
              Could not find your bank? <br />
              Please make sure that the right country is selected.
            </div>
          ) : (
            <div className="banks-list text-center mt-3">
              {filteredBanks().map((el, i) => (
                <Card
                  key={i}
                  className="cursor-pointer"
                  onClick={() => handleBankClick(el)}
                >
                  <CardBody style={{ height: "100px" }}>
                    <img src={el.logo} className="bank-logo" />
                  </CardBody>
                  <CardFooter>
                    <div
                      className="text-bold-600 mb-n1"
                      style={{ marginTop: "-0.7rem" }}
                    >
                      {el.name}
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
